<template>
	<div style="text-align: center;background-color: #EBEEF5;padding: 0 5%;" >
		<div style="text-align: left;padding:40px;margin:10px;background-color: #fff;">
			<div>
				<div id="tenderDiv">
					<h3>{{tenderInfo.name}}</h3>
					<p>
						<span>项目编号：{{tenderInfo.number}}</span>
						<span>招标方式：{{tenderInfo.way}}</span>
					</p>
					<p>
						<span>发布时间：{{tenderInfo.release_time}}</span>
						<span>报名截止时间：{{tenderInfo.end_time}}</span>
					</p>
					<el-divider></el-divider>
				</div>
				<div v-html="tenderInfo.content" id="wangTable">
				</div>
				<div style="margin-top:50px;">
					<p v-for="(item,index) in tenderInfo.path" :key="index" style="margin:5px 0;">
						<!--
						<span>附件下载：</span>
							<span @click="loadFile(item.annex_path,item.annex_name)" style="color: grey;cursor: pointer;">{{item.annex_name}}</span>
						-->
						<span>附件下载：</span>
						<a :href="loadFile(item.annex_path)" download style="text-decoration: none;color: grey;">{{item.annex_name}}</a>
					</p>
					
				</div>
			</div>
		</div>
	</div>
</template>
<style>
	#wangTable img{max-width: 100%;}
	#wangTable table{
		width:100%;
		border-top:#DCDFE6 1px solid;
		border-left:#DCDFE6 1px solid;
	}
	#wangTable table td,#wangTable table th{
		border-bottom:#DCDFE6 1px solid;
		border-right:#DCDFE6 1px solid;
		padding: 10px;
		height: 25px;
		line-height: 25px;
	}
</style>
<script>
export default {
	name:'tenderInfoContentPreview',
	data() {
		return {
			screenWidth:1300,
			tenderInfo:{
				number:'',
				way:0,
				release_time:'',
				end_time:'',
				content:'',
			}
		}
	},
	methods: {
		preview(id){
			var that=this;
			this.$axios.post(this.baseUrl+'admin/tender/searchTender?tenderInfoId='+id).then(response=>{
				if(response.data.code!='200'){
					that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
				}else{
					var info=response.data.data.res[0];
					this.tenderInfo={
						name:info.name,
						number:info.number,
						way:this.way[info.way-1].name,
						release_time:info.release_time,
						end_time:info.end_time,
						content:info.content,
						path:info.path
					}
				}
			}).catch(function (error) {window.console.log(error)});//ajax请求失败
		},
		loadFile(path) {
			return this.baseUrl2+path;
		}
					
	},
	mounted:function(){},
	beforeDestroy () {}
}
</script>

<style scoped>
	#tenderDiv{
		border-radius: 5px;margin-top: 20px;
	}
	#tenderDiv span{display: inline-block;width: 400px;}
</style>
