<template>
	<div style="padding:20px 5px">
		<el-form :inline="true" :model="formSearch" ref="formSearch" class="demo-form-inline formSearch"
			id="searchForm">
			<el-form-item label="项目名称">
				<el-input placeholder="请输入项目名称" v-model="formSearch.name" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="项目编号">
				<el-input placeholder="请输入项目编号" v-model="formSearch.number" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="公告类型">
				<el-select placeholder="请选择" v-model="formSearch.notice">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.name" :value="item.id" v-for="(item, index) in notice" :key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="招标分类">
				<el-select placeholder="请选择" v-model="formSearch.type">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.name" :value="item.id" v-for="(item, index) in type" :key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="招标方式">
				<el-select placeholder="请选择" v-model="formSearch.way">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.name" :value="item.id" v-for="(item, index) in way" :key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="发布状态">
				<el-select placeholder="请选择" v-model="formSearch.state">
					<el-option label="全部" value=""></el-option>
					<el-option label="发布" value="1"></el-option>
					<el-option label="草稿" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getTableDate(1)">查询</el-button>
			</el-form-item>
		</el-form>
		<el-button type="primary" @click="handleAdd">添加招标信息</el-button>
		<el-dialog title="招标信息添加" :visible.sync="dialogFormVisible" width="800px">
			<el-form :model="formInfo" ref="formInfo" :rules="rules">
				<el-form-item label="公告类型" :label-width="formLabelWidth" prop="notice">
					<el-select v-model="formInfo.notice" placeholder="公告类型" style="width: 100%">
						<el-option :label="item.name" :value="item.id" v-for="(item, index) in notice" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<!--
				<el-form-item label="公告编号" :label-width="formLabelWidth" prop="noticeNum">
					<el-select v-model="formInfo.noticeNum" placeholder="公告类型" style="width: 100%;">
						<el-option :label="item.name" :value="item.code" v-for="(item,index) in noticeNumber" :key="index"></el-option>
					</el-select>
				</el-form-item>
				-->
				<el-form-item label="是否发布" :label-width="formLabelWidth" style="display: none">
					<el-radio v-model="formInfo.state" label="2">是</el-radio>
					<el-radio v-model="formInfo.state" label="1">否</el-radio>
				</el-form-item>
				<el-form-item label="发布时间" :label-width="formLabelWidth">
					<el-date-picker style="width: 100%;" v-model="formInfo.releaseTime" type="datetime"
						value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="项目名称" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formInfo.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="项目编号" :label-width="formLabelWidth" prop="number">
					<el-input v-model="formInfo.number" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="招标分类" :label-width="formLabelWidth" prop="type">
					<el-select v-model="formInfo.type" placeholder="请选择招标分类" style="width: 100%">
						<el-option :label="item.name" :value="item.id" v-for="(item, index) in type" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="招标方式" :label-width="formLabelWidth" prop="way">
					<el-select v-model="formInfo.way" placeholder="请选择招标方式" style="width: 100%">
						<el-option :label="item.name" :value="item.id" v-for="(item, index) in way" :key="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="报名截止时间" :label-width="formLabelWidth"
					v-if="formInfo.notice == 1 || formInfo.notice == 2">
					<el-date-picker style="width: 100%;" v-model="formInfo.endTime" type="datetime"
						value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="上传附件" :label-width="formLabelWidth">
					<el-upload ref="upload" class="upload-demo" drag multiple :action="fileUpload" :auto-upload="false"
						:on-change="handleChange" :on-remove="handleRemove" :file-list="files">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">
							将文件拖到此处，或<em>点击上传</em>
						</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="招标内容" :label-width="formLabelWidth" prop="content">
					<tinymce ref="mychild"></tinymce>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>

		<el-table :data="tableData" border stripe style="width: 100%; margin-top: 20px">
			<el-table-column prop="name" label="项目名称" min-width="150"></el-table-column>
			<el-table-column prop="number" label="项目编号" width="150"></el-table-column>
			<el-table-column label="招标分类" width="80">
				<template slot-scope="scope">
					{{ type[scope.row.type - 1].name }}
				</template>
			</el-table-column>
			<el-table-column label="招标方式" width="100">
				<template slot-scope="scope">
					{{ way[scope.row.way - 1].name }}
				</template>
			</el-table-column>
			<el-table-column label="公告类型" width="80">
				<template slot-scope="scope">
					{{ notice[scope.row.notice - 1].name }}
				</template>
			</el-table-column>
			<el-table-column prop="end_time" label="报名截止时间" width="160"></el-table-column>
			<el-table-column label="状态" width="70">
				<template slot-scope="scope">
					<span v-if="scope.row.state == 2">草稿</span>
					<span v-else>已发布</span>
				</template>
			</el-table-column>
			<el-table-column prop="visit_num" label="访问次数" width="80" align="center"></el-table-column>
			<el-table-column label="操作"  min-width="150">
				<template slot-scope="scope" >
					<el-button size="mini"  plain type="success" @click="preview(scope)" style="margin-left: 10px;">预览</el-button>
					<el-button size="mini" type="primary" plain v-if="scope.row.state != 1"
						@click="handleReport(scope, 1)">发布</el-button>
					<el-button size="mini" type="info" plain  v-else @click="handleReport(scope, 2)">取消</el-button>
					<el-button size="mini" type="warning" plain @click="handleEdit(scope)">编辑</el-button>
					<el-button size="mini" type="danger" plain @click="handleDelete(scope)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="margin-top: 10px" background @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="formSearch.page"
			:page-sizes="[10, 20, 50, 100]" :page-size="formSearch.limit"
			layout="total, sizes, prev, pager, next, jumper" :total="formSearch.total">
		</el-pagination>
		<el-dialog title="预览" width="1100px" :visible.sync="dialogPreviewVisible">
			<TenderInfoContentPreview ref="preview"></TenderInfoContentPreview>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogPreviewVisible = false">关闭</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import Tinymce from "@components/tinymce.vue";
	import TenderInfoContentPreview from "@components/tenderInfoContentPreview";
	export default {
		name: "tender",
		components: {
			Tinymce: Tinymce,
			TenderInfoContentPreview
		},
		data() {
			return {
				formSearch: {
					name: "",
					number: "",
					notice: "",
					type: "",
					way: "",
					state: "",
					limit: 10, //每页的条数
					total: 0, //总条数
					page: 1, //当前页
				},
				dialogFormVisible: false,
				formInfo: {
					name: "",
					number: "",
					notice: "",
					noticeNum: "",
					state: "2",
					type: "",
					way: "",
					endTime: "",
					content: "",
				},
				files: [], //附件列表
				files2: [], //提交后台的附件列表
				formLabelWidth: "120px",
				rules: {
					name: [{
						required: true
					}],
					number: [{
						required: true
					}],
					notice: [{
						required: true
					}],
					type: [{
						required: true
					}], //新闻内容
					way: [{
						required: true
					}],
					endTime: [{
						required: true
					}],
					content: [{
						required: true
					}],
				},
				deleteFileId: [],
				fileUpload: "api/admin/tender/addTender", //上传图片接口
				buttonFun: "addTender",
				tableData: [],
				dialogPreviewVisible:false,
			};
		},
		methods: {
			getTableDate: function(res) {
				var that = this;
				if (res != "") {
					that.formSearch.page = 1;
				} //点击查询，恢复第一页
				this.$axios
					.post(this.baseUrl + "admin/tender/searchTender", that.formSearch)
					.then((response) => {
						console.log(response);
						if (response.data.code != "200") {
							that.$alert(response.data.msg, "提示", {
								confirmButtonText: "确定",
							});
						} else {
							that.formSearch.total = response.data.data.count;
							that.tableData = response.data.data.res;
						}
					})
					.catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
			},
			handleChange(event) {
				this.files.push(event.raw);
				this.files2.push(event.raw);
			}, //选择文件
			handleRemove(event) {
				for (var i = 0; i < this.files.length; i++) {
					if (this.files[i].uid == event.uid) {
						if (this.files[i].tender_annex_id) {
							this.deleteFileId.push(this.files[i].tender_annex_id);
						}
						this.files.splice(i, 1);
					}
				}
				for (var j = 0; j < this.files2.length; j++) {
					if (this.files2[j].uid == event.uid) {
						this.files2.splice(j, 1);
					}
				}
			}, //移除文件
			/*changeWang(html) {
			  this.$refs.mychild.initNull();
			  this.formInfo.content = html;
			},*/ //子组件fuwenben向父组件传值
			handleAdd() {
				this.deleteFileId = [];
				this.files = [];
				this.files2 = [];
				if (this.$refs["upload"] !== undefined) {
					this.$refs["upload"].clearFiles(); //清空文件
				}
				(this.buttonFun = "addTender"), (this.dialogFormVisible = true);
				this.formInfo = {
					name: "",
					number: "",
					notice: "",
					noticeNum: "",
					releaseTime: "",
					state: "2",
					type: "",
					way: "",
					endTime: "",
					content: "",
				};
				if (this.$refs["formInfo"] !== undefined) {
					this.$refs["formInfo"].resetFields();
				}
				this.$nextTick(() => {
					this.$refs.mychild.myValue = "";
				});
			},
			handleEdit(scope) {
				this.deleteFileId = [];
				this.files = [];
				this.files2 = [];
				if (this.$refs["upload"] !== undefined) {
					this.$refs["upload"].clearFiles(); //清空文件
				}
				(this.buttonFun = "updateTender"), (this.dialogFormVisible = true);
				if (this.$refs["formInfo"] !== undefined) {
					this.$refs["formInfo"].resetFields();
				}
				this.formInfo = {
					tenderInfoId: scope.row.tender_info_id,
					name: scope.row.name,
					number: scope.row.number,
					notice: scope.row.notice,
					noticeNum: scope.row.notice_num,
					releaseTime: scope.row.release_time,
					state: scope.row.state.toString(),
					type: scope.row.type,
					way: scope.row.way, //新闻内容
					endTime: scope.row.end_time,
					content: scope.row.content,
				};
				for (var i = 0; i < scope.row.path.length; i++) {
					this.files.push({
						name: scope.row.path[i].annex_name,
						url: this.baseUrl2 + scope.row.path[i].annex_path,
						tender_annex_id: scope.row.path[i].tender_annex_id,
						tender_info_id: scope.row.path[i].tender_info_id,
					});
				}
				this.$nextTick(() => {
					this.$refs.mychild.myValue = scope.row.content;
				});
			},
			handleDelete(scope) {
				var that = this;
				this.$confirm("确定删除吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).
				then(() => {
					that.$axios.get(this.baseUrl + "admin/tender/deleteTender?tenderInfoId=" + scope.row
						.tender_info_id).
					then((response) => {
						if (response.data.code == "200") {
							that.getTableDate();
						}
						that.$alert(response.data.msg, "提示", {
							confirmButtonText: "确定"
						});
					}).catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
				}).catch(() => {})
			},
			handleReport(scope, state) {
				var that = this;
				this.$axios
					.get(
						this.baseUrl +
						"admin/tender/ReleaseTender?tenderInfoId=" +
						scope.row.tender_info_id +
						"&state=" +
						state
					)
					.then((response) => {
						if (response.data.code == "200") {
							that.getTableDate();
						}
						that.$alert(response.data.msg, "提示", {
							confirmButtonText: "确定"
						});
					})
					.catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
			},
			submitForm() {
				var that = this;
				that.formInfo.content = this.$refs.mychild.myValue;
				let param = new FormData();
				if (this.buttonFun == "updateTender") {
					param.append("tenderInfoId", that.formInfo.tenderInfoId);
				}
				for (var i = 0; i < this.files2.length; i++) {
					param.append("files[]", this.files2[i]);
				}
				param.append("name", that.formInfo.name),
					param.append("state", Number(that.formInfo.state)),
					param.append("number", that.formInfo.number),
					param.append("notice", that.formInfo.notice),
					param.append("noticeNum", that.formInfo.noticeNum),
					param.append("type", that.formInfo.type),
					param.append("way", that.formInfo.way),
					param.append("endTime", that.formInfo.endTime),
					param.append("content", this.$refs.mychild.myValue),
					param.append("deleteFileId", that.deleteFileId);
				param.append("releaseTime", that.formInfo.releaseTime);
				this.$refs["formInfo"].validate((valid) => {
					if (valid) {
						this.$axios
							.post(this.baseUrl + "admin/tender/" + this.buttonFun, param, {
								headers: {
									"Content-Type": "multipart/form-data"
								},
							})
							.then((response) => {
								if (response.data.code == "200") {
									if (that.$refs["upload"] !== undefined) {
										that.$refs["upload"].clearFiles(); //清空文件
									}
									that.dialogFormVisible = false;
									that.$alert(response.data.msg, "提示", {
										confirmButtonText: "确定",
									});
									that.getTableDate();
								}
								that.$alert(response.data.msg, "提示", {
									confirmButtonText: "确定",
								});
							})
							.catch(function(error) {
								window.console.log(error);
							}); //ajax请求失败
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			handleSizeChange(val) {
				this.formSearch.limit = val;
				this.getTableDate("");
			}, //每页条数发生改变
			handleCurrentChange(val) {
				this.formSearch.page = val;
				this.getTableDate("");
			}, //翻页
			preview(scope){
				this.dialogPreviewVisible=true;
				var that=this;
				setTimeout(function(){that.$refs.preview.preview(scope.row.tender_info_id);},200)
			}
		},
		mounted: function() {
			this.getTableDate("");
			//Object.assign(this.$refs.mychild.myValue);
			Object.assign(this.formInfo);
		},
	};
</script>

<style scoped>
	#searchForm .el-input {
		width: 217px;
	}
</style>
